<template>
    <div class="authentication__form">
        <!-- signing login section -->
        <div>
            <!-- signing login section -->
            <form
                class="signin-section form-block"
                @submit.prevent="validate"
            >
                <div class="form-block__header">
                    <h2 class="form-block__title">
                        OTP
                    </h2>
                </div>

                <div class="form-block__body">
                    <!-- OTP -->
                    <form-input
                        v-model.trim="otp"
                        :field.sync="otp"
                        placeholder="123 456"
                        type="text"
                    />
                    <!-- OTP -->

                    <!-- submit -->
                    <custom-button
                        :loading="loading"
                        class="btn-base btn-main button-width-100"
                    >
                        Sign In
                    </custom-button>
                    <!-- submit -->
                </div>

                <div class="form-block__footer" />
            </form>
            <!-- signing login section -->
        </div>
    <!-- signing login section -->
    </div>
</template>

<script>
// validation
import { validationMixin } from 'vuelidate';
import formValidationMixin from '@/mixins/formValidationMixin';
import { validationMessage } from 'vuelidate-messages';
import { validationRules } from '@/validation/login/Rules';
import { formMessages } from '@/validation/login/Messages';
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_LOGIN_WRITER
} from '@/store/modules/writer/action-types';

import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer');

export default {
    name: 'OtpForm',
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        otp: ''
    }),
    methods: {
        ...mapWriterActions([
            ACTION_LOGIN_WRITER
        ]),
        validationMessage: validationMessage(formMessages),
        async submit() {
            const vm = this
            try {
                vm.loading = true
                await this[ACTION_LOGIN_WRITER]({
                    otp: this.otp.trim()
                })
                this.$emit('loginSuccess')
            } catch (e) {
                // desired loader behavior: if login is successful, loader keeps going until redirect complete
                vm.loading = false
                eventBus.$emit('showSnackBar', e, 'error')
            }
        }
    }
}
</script>
